const DocMenuConfig = [
  {
    pages: [
      {
        heading: "dashboard",
        route: "/dashboard",
        svgIcon: "/media/icons/duotune/general/gen001.svg",
        fontIcon: "bi-app-indicator",
        meta: {
          permissions: [88, 1, 2],
        },
      },
      {
        heading: "berita",
        route: "/berita",
        svgIcon: "/media/icons/duotune/files/fil004.svg",
        fontIcon: "bi-app-indicator",
        meta: {
          permissions: [88, 1, 2],
        },
      },
      {
        heading: "Search",
        route: "/search",
        svgIcon: "/media/icons/duotune/general/gen021.svg",
        fontIcon: "bi-app-indicator",
        meta: {
          permissions: [88, 1, 2],
        },
      },
      // {
      //   heading: "Highlight Pdf",
      //   route: "/highlight-pdf",
      //   svgIcon: "/media/icons/duotune/art/art003.svg",
      //   fontIcon: "bi-app-indicator",
      //   meta: {
      //     permissions: [88, 1, 2],
      //   },
      // },
      // {
      //   heading: "Ocr Media Cetak",
      //   route: "/OcrImage",
      //   svgIcon: "/media/icons/duotune/general/gen005.svg",
      //   fontIcon: "bi-app-indicator",
      //   meta: {
      //     permissions: [88, 1, 2],
      //   },
      // },
    ],
  },
  {
    heading: "master",
    route: "/master",
    meta: {
      permissions: [88],
    },
    pages: [
      {
        heading: "users",
        route: "/users",
        svgIcon: "/media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-app-indicator",
        meta: {
          permissions: [88],
        },
      },
      // {
      //   heading: "media",
      //   route: "/medias",
      //   svgIcon: "/media/icons/duotune/general/gen008.svg",
      //   fontIcon: "bi-app-indicator",
      //   meta: {
      //     permissions: [88, 1, 2],
      //   },
      // },
      // {
      //   heading: "Labeling Text",
      //   route: "/sequence-labeling",
      //   svgIcon: "/media/icons/duotune/general/gen003.svg",
      //   fontIcon: "bi-app-indicator",
      //   meta: {
      //     permissions: [88, 1, 2],
      //   },
      // },
      {
        heading: "Log Crawler",
        route: "/log/crawler",
        svgIcon: "/media/icons/duotune/general/gen049.svg",
        fontIcon: "bi-app-indicator",
        meta: {
          permissions: [1, 2],
        },
      },
      {
        sectionTitle: "logs",
        route: "/log",
        svgIcon: "/media/icons/duotune/general/gen049.svg",
        fontIcon: "bi-app-indicator",
        meta: {
          permissions: [88],
        },
        sub: [
          {
            heading: "Log User",
            route: "/log/user",
          },
          {
            heading: "Log Crawler",
            route: "/log/crawler",
          },
        ],
      },
    ],
  },
];

export default DocMenuConfig;

import moment from "moment";

export function thousandSeparator (x: number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export function typeSource (source: string) {
    const medsos = ['youtube', 'instagram', 'twitter', 'facebook', 'tiktok'];

    if (medsos.includes(source.toLowerCase())) {
        return 'medsos';
    }

    return 'medkon';
};

export function profileSource(source: string) {
    const medkon = [
        { value: "media_cetak", label: "Media Cetak", icon: "far fa-newspaper", color: '#999B9E' },
        { value: "media_tv", label: "Media TV", icon: "fas fa-tv", color: '#DC3545' },
        { value: "news", label: "Media Online", icon: "fas fa-pager", color: '#28C76F' },
        { value: "radio", label: "Radio", icon: "bi bi-broadcast-pin", color: '#FFC107' },
        { value: "running_text", label: "Running Text", icon: "bi bi-card-text", color: "#FFA008" },
    ];

    const medsos = [
        { value: "youtube", label: "Youtube", icon: "fab fa-youtube", color: '#DC3545' },
        { value: "instagram", label: "Instagram", icon: "fab fa-instagram", color: '#E1306C' },
        { value: "twitter", label: "Twitter", icon: "fa fa-times", color: '#1DA1F2' },
        { value: "facebook", label: "Facebook", icon: "fab fa-facebook-f", color: '#4267B2' },
        { value: "tiktok", label: "TikTok", icon: "fab fa-tiktok", color: '#999B9E' },
    ];

    const sources = [...medkon, ...medsos];

    const found = sources.find(item => item.value === source.toLowerCase());

    return (
        found || { value: "", label: "", icon: "", color: '' }
    );
}

export function formatDateIndo(date: string) {
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
}

export function getSentiment(sentiment: any) {
    const data_sentiment = [
        { value: 1, label: "Positif", icon: "far fa-smile text-success" },
        { value: 0, label: "Netral", icon: "far fa-meh text-primary" },
        { value: -1, label: "Negatif", icon: "far fa-frown text-danger" },
    ];

    const sentimentNumber = typeof sentiment === "string" ? parseInt(sentiment, 10) : sentiment;
    const found = data_sentiment.find(item => item.value === sentimentNumber);

    return ( found || { value: "-", label: "-", icon: "" } )
}

export function getEmotion(emotion: any) {
    const data_emotion = [
        { value: 0, label: "Netral", icon: "far fa-meh-blank fs-2x text-primary" },
        { value: 1, label: "Senang", icon: "far fa-grin-squint text-success fs-2x" },
        { value: 2, label: "Takut", icon: "far fa-flushed text-warning fs-2x" },
        { value: 3, label: "Sedih", icon: "far fa-sad-tear text-complete fs-2x" },
        { value: 4, label: "Marah", icon: "far fa-angry text-danger fs-2x" },
    ];

    const emotionNumber = typeof emotion === "string" ? parseInt(emotion, 10) : emotion;
    const found = data_emotion.find(item => item.value === emotionNumber);

    return ( found || { value: "-", label: "-", icon: "-" } )
}
